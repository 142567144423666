import React from "react";
//import { useIntl, Link } from "gatsby-plugin-react-intl";
import ComponentColumnRight from "./component-column-right";

const ComponentColumn1 = ({
  lefttitle,
  leftcontent,
  label,
  heading,
  content,
  linkpath,
  linkpathexternal,
  linktext,
}) => {
  //const dispLabel = label ? (
  //  <p if className="message-label-bold txt txt-rot">
  //    {label}
  //  </p>
  //) : (
  //  ""
  //);
  return (
    <div className="flex-row">
      <div className="flex-column-7 sect_PageMission_catch__j9813">
        {/* {dispLabel} */}
        <h2>{lefttitle}</h2>
        <p dangerouslySetInnerHTML={{ __html: leftcontent }} />
      </div>
      <div className="flex-column-1"></div>
      <ComponentColumnRight
        label={label}
        heading={heading}
        content={content}
        linkpath={linkpath}
        linkpathexternal={linkpathexternal}
        linktext={linktext}
      />
    </div>
  );
};

export default ComponentColumn1;
