import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import ComponentTextblock1 from "../../components/component-textblock-1";
import ComponentTextblock2 from "../../components/component-textblock-2";
import ComponentColumn1 from "../../components/component-column-1";
import SectionP60 from "../../components/section/section-p60";
//import { graphql } from "gatsby";
//import { StaticImage } from "gatsby-plugin-image"
import { useIntl, Link } from "gatsby-plugin-react-intl";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { StaticImage } from "gatsby-plugin-image";

const PageMission = ({ data, location }) => {
  const intl = useIntl();
  return (
    <Layout location={location} mode="bls">
      <Seo
        title={
          intl.locale === "en"
            ? `Reimagining the way humans, robots, and information interact`
            : `Neuartige Interaktion von Menschen, Robotern und Informationen`
        }
        description={
          intl.locale === "en"
            ? `Humans interact with technology through ever more intelligent interfaces. We seamlessly interfaced, feeding off of each other in both cyberspace and physical space.`
            : `Menschen interagieren durch intelligente Schnittstellen mit Technologien. Durch eine nahtlose Verbindung unterstützen sich virtueller und reeller Raum.`
        }
        ogImage="https://images.ctfassets.net/t2ifj85iosf3/5j3rV4LpE7Y8SW9EfmLau7/f52dd02961c13ea58f770cb1c7237362/E188.jpg?w=1200&h=630&fit=fill"
      />
      <section className="sect sect_PageMission_catch sect-bg-bls">
        <div className="container">
          <ComponentTextblock1
            title={
              intl.locale === "en"
                ? `Symbiosis of humans and technology for a better society`
                : `Symbiose zwischen Mensch und Technologien für eine bessere Gesellschaft`
            }
            subtitle={
              intl.locale === "en" ? `Vision and Mission` : `Vision und Auftrag`
            }
            content={
              intl.locale === "en"
                ? `We envision a future where humans and technology are seamlessly interfaced, feeding off of each other in both cyberspace and physical space to revolutionize the way society functions and improve the lives of its citizens.`
                : `Wir stellen uns eine Zukunft vor, in der Menschen und Technologie nahtlos miteinander verbunden sind und sich sowohl im Cyberspace als auch im physischen Raum gegenseitig unterstützen. Die Funktionsweise der Gesellschaft soll revolutioniert und das Leben ihrer Bürger verbessert werden.`
            }
          />
          <SectionP60>
            <ComponentTextblock2>
              {intl.locale === "en"
                ? `Through Cybernics and IoH/IoT (Internet of Humans and Things), we
  connect the human nervous system, physiological system, and
  environmental system to a supercomputer through advanced
  AI-Robots. Big data from humans and things are accumulated,
  analyzed, and AI processed, allowing for the delivery of Precision
  Medicine, advanced elderly care, and safe labor practices.`
                : `Durch Cybernics und IoH/IoT (Internet der Dinge) verbinden wir das menschliche Nervensystem, das physiologische System und das Umweltsystem durch fortschrittliche KI-Roboter mit einem Supercomputer . Große Datenmengen von Menschen und Dingen werden akkumuliert, analysiert und KI-verarbeitet, was die Bereitstellung von Präzisionsmedizin, fortschrittlicher Altenpflege und sicheren Arbeitspraktiken ermöglicht.`}
            </ComponentTextblock2>
          </SectionP60>
          <ComponentColumn1
            lefttitle={
              intl.locale === "en"
                ? `Medical Cybernics Technology - support, assist, enhance, and regenerate neurological and physical function of wearers`
                : `Medizinische Cybernics Technologie - unterstützen, fördern und regenerieren neurologischer und körperlicher Funktionen des Trägers `
            }
            leftcontent={
              intl.locale === "en"
                ? `Our mission is to deliver tailored medical treatment to meet the precise needs of each patient, enhancing mobility and independence. Our flagship Cybernic devices named HAL act as the linchpin between humans and technology, providing new insight into what our bodies are doing neurologically when we move.`
                : `Unsere Mission ist es, maßgeschneiderte medizinische Behandlungen zu liefern, die genau auf die Bedürfnisse jedes einzelnen Patienten abgestimmt sind und die Mobilität und Unabhängigkeit verbessern. Unser Flaggschiff, die Cybernic-Geräte mit dem Namen HAL, fungieren als Bindeglied zwischen Mensch und Technik und liefern neue Erkenntnisse darüber, was unser Körper neurologisch tut, wenn wir uns bewegen.`
            }
            label={
              intl.locale === "en"
                ? `Reading Recommendation`
                : `Lese-Empfehleung`
            }
            heading={
              intl.locale === "en" ? (
                <span>
                  Cybernics 2014, Springer
                  <br />
                  Fusion of human, machine and information systems
                </span>
              ) : (
                <span>
                  Cybernics 2014, Springer
                  <br />
                  Fusion von Mensch, Maschine und Informationssystemen
                </span>
              )
            }
            content={
              intl.locale === "en"
                ? `Editors: Sankai, Yoshiyuki, Suzuki, Kenji, Hasegawa, Yasuhisa (Eds.)`
                : `Redakteure: Sankai, Yoshiyuki, Suzuki, Kenji, Hasegawa, Yasuhisa (Eds.)`
            }
            linkpathexternal={
              intl.locale === "en"
                ? `https://link.springer.com/book/10.1007/978-4-431-54159-2`
                : `https://link.springer.com/book/10.1007/978-4-431-54159-2`
            }
            linktext={intl.locale === "en" ? `more` : `mehr`}
          />
        </div>
      </section>

      <section className="sect position-relative overflow-visible">
        <StaticImage
          src="../../images/company/treatment-big-teaser@2x.png"
          class="d-sm-none"
        />
        <StaticImage
          src="../../images/company/treatment-2-big-teaser-mobile@2x.png"
          class="d-sm-block"
        />

        <div className="floating-box">
          <div className="p-8 bg-rot">
            <h2 className="h3">
              {intl.locale === "en" ? `HAL Treatment` : `HAL-Therapie`}
            </h2>
            <p>
              {intl.locale === "en"
                ? `Maximize your therapy effect and efficiency with HAL, the World first neurologically controlled wearble cyborg to promote neuromuscular reconnection or remodulation and to improve locomotor function`
                : `Maximieren Sie Ihren Therapieerfolg und die Therapieeffektivität mit dem HAL. HAL ist der weltweit erste neurologisch kontrollierte tragbare Cyborg der die neuromuskuläre Neuverknüpfung oder Anpassung fördert und so die lokomotorische Funktion verbessert.`}
            </p>
            <div>
              <Link
                to="/treatment/spinal/"
                className="link-border link-border-white"
              >
                {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="sect sect_PageMission_second">
        <div className="container">
          <div className="flex-row">
            <div className="flex-column-5 p-5 pb-9 pr-9">
              <h2 className="h3">HAL Treatment</h2>
              <p>
                Application on Athletes vel eum iriure dolor in hendrerit in
                vulputate velit esse molestie consequat, vel illum dolore eu
                feugiat nulla facilisis at vero eros et accumsan et iusto odio
                dignissim qui blandit praesent
              </p>
              <div>
                <Link to="#" className="link-border link-border-white">
                  Mehr erfahren
                </Link>
              </div>
              <div className="blck blck-deco blck-deco-bottom-right sect-bg-rot"></div>
            </div>
            <div className="flex-column-7"></div>
          </div>
        </div>
      </section> */}

      <section className="sect sect-qa">
        <div className="container">
          <p className="h2 txt txt-rot mb-5">
            {intl.locale === "en"
              ? `Learn more about us and the Cybernics Technology`
              : `Erfahren Sie mehr über uns und die Cybernics Technologie`}
          </p>
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en" ? `About us` : `Über uns`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Our revolutionary technology is a game-changer of what we have
  as medical experts and neuro-rehabilitation professionals.`
                    : `Unsere revolutionäre Technologie ist bahnbrechend für medizinische Experten und Fachkräfte in der neurologischen Rehabilitation.`}
                </p>
                <Link to="/company/about/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>Cyberdyne Inc.</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `The significant existence of technology is to support, assist,
  enhance, and regenerate functions of humans.`
                    : `Die entscheidende Aufgabe der existierende Technologie ist die menschliche Funktion zu unterstützen, verbessern und regenerieren.`}
                </p>
                <Link to="/company/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            {/* <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  Vision and Mission
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
{intl.locale === "en"
                    ? `We envision a future where humans and technology are
  seamlessly interfaced, feeding off of each other.`
                    : `Wir stellen uns eine Zukunft vor, in der Menschen und Technologie nahtlos miteinander verbunden sind und sich gegenseitig unterstützen`}                </p>
                <Link to="/company/mission/" className="link-button">
                                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}

                </Link>
              </AccordionItemPanel>
            </AccordionItem> */}
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>Cybernics</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `“Cybernics” (adjective: Cybernic) is a new academic field that
  is centered around cybernetics, mechatronics and informatics.`
                    : `“Cybernics" (Adjektiv: cybernic) ist ein neues akademisches Feld, das sich mit Kybernetik, Mechatronik und Informatik beschäftigt.`}
                </p>
                <Link to="/company/cybernics/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en" ? `Contact/Access` : `Anfahrt`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Cyberdyne Care Robotics GmbH based in Bochum, North
  Rhine-Westphalia. We welcome people from all over the world.`
                    : `Die Cyberdyne Care Robotics GmbH hat ihren Sitz in Bochum, Nordrhein-Westfalen. Wir begrüßen Menschen aus der ganzen Welt.`}
                </p>
                <div>
                  <Link to="/company/access/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </section>
    </Layout>
  );
};

export default PageMission;
