import React from "react"

const SectionP60 = ({ children }) => {
  return (
    <div className="sect sect_PageMission_mid">
      {children}
    </div>
  )
}

export default SectionP60
