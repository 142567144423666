import React from "react";
import { Link } from "gatsby-plugin-react-intl";

const ComponentColumnRight = ({
  label,
  heading,
  content,
  linkpath,
  linkpathexternal,
  linktext,
}) => {
  const dispLabel = label ? (
    <p if className="message-label-bold txt txt-rot">
      {label}
    </p>
  ) : (
    ""
  );
  return (
    <div className="flex-column-4">
      {dispLabel}
      <p className="font-weight-bold">{heading}</p>
      <p>{content}</p>
      {linkpath ? (
        <div>
          <Link to={linkpath} className="link_simple">
            {linktext}
          </Link>
        </div>
      ) : (
        <div>
          <a href={linkpathexternal} className="link_simple" target="_blank" rel="noreferrer">
            {linktext}
          </a>
        </div>
      )}
    </div>
  );
};

export default ComponentColumnRight;
